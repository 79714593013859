  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 80vh;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  *{
    box-sizing: border-box;
  }
  .glassCard{
  background: rgba( 255, 255, 255, 0.35 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 0px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  .loginback{
    background:linear-gradient(6deg, rgba(220,249,221,1) 0%, rgba(150,223,179,1) 100%, rgba(188,231,166,0.5664390756302521) 255%);
  }
  @font-face {
    font-family: "Light";   /*Can be any text*/
    src: local("TheQueen-Regular"),
      url("./fonts/TheQueen-Regular.ttf") format("truetype");
  }
.backgroundforall { 
    width: 100%;
    display: block;
    position: relative;
}

.backgroundforall::after {
    content: "";
   background-image: url('/src/Assets/18129294.jpg');
   background-size:cover;
   background-repeat:repeat-x;
   background-position: center;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}


.cards{
/* From https://css.glass */
background: rgba(255, 255, 255, 0.15);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(1.1px);
-webkit-backdrop-filter: blur(1.1px);
border: 1px solid rgba(255, 255, 255, 0.61);
cursor: pointer;
width: 160px;
}
.cards:hover{
transform: scale(0.9);
}

.layout { 
    width: 100%;
    display: block;
    position: relative;
}

.layout::after {
    content: "";
   background-image: url('/src/Assets/18129294.jpg');
   background-size:cover;
   background-repeat:repeat-x;
   background-position: center;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.eventBox{
  background-image: url('/src/Assets/announcement/orange-holi-colors-crowd.jpg');
}
.eventBox3{
 background-image: linear-gradient(to right, #DECBA4, #3E5151);
}
.eventBox2{
background-image: linear-gradient(to right, #fc5c7d, #6a82fb);
}
.breadLink{
display: flex;
align-items: center;
text-decoration: none;
color: #262626;
}