body {
    font-family: sans-serif;
    font-size: 15px;
  }
  
  .tree {
    transform: rotate(180deg);
    transform-origin: 50%;
  }
  
  .tree ul {
    position: relative;
    padding: 1em 0;
    white-space: nowrap;
    margin: 0 auto;
    text-align: center;
  }
  .tree ul::after {
    content: "";
    display: table;
    clear: both;
  }
  
  .tree li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 1em 0.5em 0 0.5em;
  }
  .tree li::before, .tree li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #51868A ;
    width: 50%;
    height: 1em;
  }
  .tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #51868A ;
  }
  .tree li:only-child::after, .tree li:only-child::before {
    display: none;
  }
  .tree li:only-child {
    padding-top: 0;
  }
  .tree li:first-child::before, .tree li:last-child::after {
    border: 0 none;
  }
  .tree li:last-child::before {
    border-right:1px solid #51868A ;
    border-radius: 0 5px 0 0;
  }
  .tree li:first-child::after {
    border-radius: 5px 0 0 0;
  }
  
  .tree ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #51868A ;
    width: 0;
    height: 1em;
  }
  
  .tree li a {
    border: 1px solid #51868A ;
    padding: 0.5em 0.75em;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    color: #333;
    position: relative;
    top: 1px;
    transform: rotate(180deg);
  }
  
  .tree li a:hover,
  .tree li a:hover + ul li a {
    background: #e9453f;
    color: #fff;
    border: 1px solid #e9453f;
  }
  
  .tree li a:hover + ul li::after,
  .tree li a:hover + ul li::before,
  .tree li a:hover + ul::before,
  .tree li a:hover + ul ul::before {
    border-color: #e9453f;
  }